$(document).ready(initPage);

function initPage() {
  $(".feature-item:not('is-active')").click(function () {
    const activeTab = $(this).data('tab');
    $(this).siblings().removeClass('is-active');
    $(this).addClass('is-active');
    $('.feature-desc-item').removeClass('is-active');
    $(`.feature-desc-item[data-tab-content="${activeTab}"]`).addClass(
      'is-active'
    );
  });
}
